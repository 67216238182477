import React from 'react';

const DiscordLogin = () => {
  const handleDiscordLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;

  };

  return (
    <div>
      <h1>Link Your Discord Account</h1>
      <button onClick={handleDiscordLogin}>Login with Discord</button>
    </div>
  );
};

export default DiscordLogin;
