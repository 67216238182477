import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './LinkDiscord.css';

const AccountCard = ({ accountType, iconSrc, username, id, isLinked, onLink, buttonLogo, buttonText }) => (
  <div className={`account-card ${accountType.toLowerCase()}-account`}>
    <h3 className="account-type">{accountType} Account</h3>
    <img src={iconSrc} alt={`${accountType} avatar`} className="account-avatar" />
    <p className="account-info">
      {username} <span className="account-id">({id})</span>
    </p>
    <button className={`link-button ${isLinked ? 'linked' : ''}`} onClick={onLink}>
      <img 
        src={buttonLogo} 
        alt={`${accountType} logo`} 
        className="button-logo"
      />
      <span className="button-text">{isLinked ? `Linked to ${accountType}` : buttonText}</span>
    </button>
  </div>
);

const LinkDiscord = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const steamAvatar = queryParams.get('steamAvatar');
  const discordAvatar = queryParams.get('discordAvatar');
  const steamID = queryParams.get('steamID');
  const discordID = queryParams.get('discordID');
  const [discordLinked, setDiscordLinked] = useState(false);

  useEffect(() => {
    if (discordID) {
      setDiscordLinked(true);
    }
  }, [discordID]);

  const handleDiscordLink = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;  // Update the URL to your backend
  };

  return (
    <main className="link-account-page">
      <img 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c01c7c33b66b11f5fa0f662991f8326f9a066ae3465a06053c2cff2cb297fbd4" 
        alt="Background" 
        className="background-image" 
      />
      <section className="content-container">
        <h1 className="page-title">Link Account</h1>
        <p className="success-message">Steam Account Linked Successfully!</p>
        <div className="accounts-container">
          <AccountCard 
            accountType="Steam"
            iconSrc={steamAvatar || 'https://cdn.builder.io/api/v1/image/assets/TEMP/dbb64ffa7d551a473d00504c70bed94b28c41c7b29ec7bec9a18075b21160d20?placeholderIfAbsent=true&apiKey=8d4c3bcbed4e4672bd59deb057d34dc1'}
            username="Steam Account"
            id={steamID || 'Linked'}
            isLinked={true}
            buttonLogo="https://cdn.builder.io/api/v1/image/assets/TEMP/34fd451222e55634e44f32fae8645e944d59ba76eb344f5a4bcc2c8336c90a19?placeholderIfAbsent=true&apiKey=8d4c3bcbed4e4672bd59deb057d34dc1"
            buttonText="Link your Steam"
          />
          <AccountCard
            accountType="Discord"
            iconSrc={discordAvatar || 'https://logo.com/image-cdn/images/kts928pd/production/5b24e49fd89287ff1eb5bbc4cf93cb038c3384ef-512x512.png?w=1920&q=72&fm=webp'}
            username="Discord Account"
            id={discordID || 'Not Linked'}
            isLinked={discordLinked}
            onLink={handleDiscordLink}
            buttonLogo="https://cdn.builder.io/api/v1/image/assets/TEMP/f7ca18a5d1b54745cde7167e8eee62aea8debdcdb5de6ca92cbc6c8ea25e3f5f?placeholderIfAbsent=true&apiKey=8d4c3bcbed4e4672bd59deb057d34dc1"
            buttonText="Link your Discord"
          />
        </div>
      </section>
    </main>
  );
};

export default LinkDiscord;
