import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SteamLogin from './SteamLogin';  // Correctly importing components
import DiscordLogin from './DiscordLogin';
import LinkDiscord from './LinkDiscord';  // Ensure the path is correct
import Summary from './Summary';        // Ensure the path is correct

const App = () => {
  return (
    <Router>
      <Routes>
        
        <Route path="/" element={<SteamLogin />} />
        <Route path="/discord-login" element={<DiscordLogin />} />
        <Route path="/link-discord" element={<LinkDiscord />} />
        <Route path="/summary" element={<Summary />} />
      </Routes>
    </Router>
  );
};

export default App;  // Ensure you're exporting App correctly
