import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Summary.css';

const AccountCard = ({ accountType, iconSrc, username, id }) => (
  <div className={`account-card ${accountType.toLowerCase()}-account`}>
    <h3 className="account-type">{accountType} Account</h3>
    <img src={iconSrc} alt={`${accountType} icon`} className="account-icon" />
    <p className="account-info">
      {username} <br />
      <span className="account-id">{id}</span>
    </p>
  </div>
);


const ActionButton = ({ text, className, onClick }) => (
  <button className={`action-button ${className}`} onClick={onClick}>
    {text}
  </button>
);

const Summary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const steamAvatar = queryParams.get('steamAvatar');
  const discordAvatar = queryParams.get('discordAvatar');
  const steamID = queryParams.get('steamID');
  const discordID = queryParams.get('discordID');  // Extract discordID from query params
  const isBoosting = queryParams.get('isBoosting') === 'true';

  const [steamLinked, setSteamLinked] = useState(false);
  const [discordLinked, setDiscordLinked] = useState(false);

  useEffect(() => {
    if (steamID) {
      setSteamLinked(true);
    }
    if (discordAvatar) {
      setDiscordLinked(true);
    }
  }, [steamID, discordAvatar]);

  const handleUnlinkAccount = async () => {
    if (!steamID) {
      alert('Steam ID is not available for unlinking.');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/unlink/${steamID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        alert('Account unlinked successfully!');
        navigate('/');
      } else {
        const errorData = await response.json();
        alert(`Failed to unlink account: ${errorData.message}`);
      }
    } catch (err) {
      alert('Error unlinking account. Please try again later.');
    }
  };

  const handleJoinDiscord = () => {
    window.location.href = 'https://discord.gg/pandarust';
  };

  const accounts = [
    {
      type: 'Steam',
      iconSrc: steamAvatar || 'https://cdn.builder.io/api/v1/image/assets/TEMP/dbb64ffa7d551a473d00504c70bed94b28c41c7b29ec7bec9a18075b21160d20?placeholderIfAbsent=true&apiKey=8d4c3bcbed4e4672bd59deb057d34dc1',
      username: 'Your Steam ID',
      id: steamID || 'N/A',
    },
    {
      type: 'Discord',
      iconSrc: discordAvatar || 'https://logo.com/image-cdn/images/kts928pd/production/5b24e49fd89287ff1eb5bbc4cf93cb038c3384ef-512x512.png?w=1920&q=72&fm=webp',
      username: 'Your Discord ID',
      id: discordID || 'N/A',  // Show the discordID here
    },
  ];

  return (
    <main className="account-linking">
      <div className="content-wrapper">
        <img
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/c01c7c33b66b11f5fa0f662991f8326f9a066ae3465a06053c2cff2cb297fbd4"
          alt="Background"
          className="background-image"
        />
        <section className="linking-section">
          <h1 className="section-title">Link Account</h1>
          <p className="success-message">Steam And Discord Account Linked Successfully!</p>
          <div className="accounts-container">
            {accounts.map((account, index) => (
              <AccountCard key={index} accountType={account.type} iconSrc={account.iconSrc} username={account.username} id={account.id} />
            ))}
          </div>
          <div className="actions-container">
            <ActionButton text="Join Discord Server" className="discord-join" onClick={handleJoinDiscord} />
            <ActionButton text={`Boost Status: ${isBoosting ? 'Active' : 'Not Active'}`} className="boost-status" />
            <ActionButton text="Unlink Accounts" className="unlink-accounts" onClick={handleUnlinkAccount} />
          </div>
        </section>
      </div>
    </main>
  );
};

export default Summary;